
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.notfound-buttons {
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  max-width: 660px;
  margin: auto;

  a {
    width: 33%;
    padding: 20px 0;
    transition: 0.3s;
  }
}

.falling-image {
  transition-property: transform;
  transition-duration: 1s;
}

.falling-image:hover {
  animation-name: rotate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
