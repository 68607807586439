
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@import '~bulma/sass/utilities/initial-variables.sass';

div {
  cursor: pointer;
  color: $red;
  transition: 300ms;

  svg {
    transition: 300ms;
  }
}

.hidden-button {
  width: 0;
  height: 0;
  svg {
    font-size: 0;
  }
}
