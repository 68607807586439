
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

ul {
  list-style-type: disc !important;
  padding-left: $size-6;
}

h4 {
  padding-bottom: $size-7 !important;
  margin-bottom: $size-7 !important;
}

li:hover {
  background: $hover-background;
}

h4 > a,
li > a {
  color: $color-text !important;
}

h4 > a:hover {
  color: $color-link !important;
}
