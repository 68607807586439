
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.button {
  vertical-align: bottom;
  margin-left: 1rem;
}

.add-section {
  margin-top: 1.5rem;
}

.badge {
  @include button-badge-primary;
}
