
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.outing-date-header {
  margin-top: $size-6;
  margin-bottom: $size-7;
  border-bottom: solid $color-base-c2c 2px;
}

h4 > a,
h6 > a,
.outing-date-header > a,
li > a {
  color: $color-text !important;
}

h4 > a:hover,
h6 > a:hover,
.outing-date-header > a:hover {
  color: $color-link !important;
}

.dashboard-list > a:nth-child(2n + 1) {
  background-color: $body-background-color;
}
