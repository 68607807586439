
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.button-bar {
  font-size: 1.5rem;
}

.button-bar > a {
  color: $text;
}

.button-bar > span,
.button-bar > a {
  margin-left: 0.25rem;
  cursor: pointer;
}
.button-bar > *:hover {
  color: $black;
}

.title {
  .outing-date {
    margin-left: 0.5rem;
  }
}

@media print {
  .title {
    font-size: 1.5rem !important;
  }
}
