
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.card {
  transition: 0.1s;
  border: $card-border;
  background: white;
}

.card:hover,
.is-highlighted {
  transition: 0.2s;
  box-shadow: $card-hover-shadow;
  background: $hover-background;
}
