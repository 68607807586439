
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.document-card-container {
  position: relative;

  .document-card {
    border-radius: $radius;
    border-left: 2px solid $color-base-c2c;
  }
}

.delete-button {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  font-size: 2rem;
}

@media screen and (max-width: $tablet) {
  .document-card-container .document-card {
    border-left: $card-border;
  }

  .delete-button {
    right: 0;
  }
}
