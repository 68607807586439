
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.meta {
  display: none;
}

.publication {
  //display: block;
  position: relative;
  margin-bottom: $size-7;

  a,
  a:hover {
    color: currentColor;
    text-decoration: underline;
  }
}
