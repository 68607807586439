
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.dashboard-link {
  .dashboard-link-main {
    flex-grow: 1;
    padding-left: 3px;
    padding-right: 3px;
  }
}
.dashboard-link:visited {
  color: $grey-light;
}
