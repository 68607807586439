
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.pagination-link {
  height: 1.75em;
}
@media screen and (max-width: 340px) {
  .limit-selector {
    display: none;
  }
}
