
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.input-document {
  @media screen and (min-width: $tablet) {
    margin-right: 1rem;
  }
}
