
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.association-items {
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0.1rem !important;
  }
}

.document-row {
  padding: 2px;
  margin-bottom: 0 !important;
}
