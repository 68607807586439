
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.is-grid {
  display: grid;
  direction: rtl;
}

.is-1-icons {
  grid-template-columns: repeat(1, 1fr);
}

.is-2-icons {
  grid-template-columns: repeat(2, 1fr);
}

.is-3-icons {
  grid-template-columns: repeat(3, 1fr);
}

.is-4-icons {
  grid-template-columns: repeat(4, 1fr);
}

.is-5-icons {
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: $tablet) {
  .is-2-icons,
  .is-3-icons,
  .is-4-icons {
    grid-template-columns: repeat(2, 1fr);
  }

  .is-5-icons {
    grid-template-columns: repeat(3, 1fr);
  }
}
