
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.dropdown-menu {
  @media screen and (max-width: $tablet) {
    max-width: 100%;
  }
}
