
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.discourse-post {
  border-top: 6px solid $color-base-c2c;
  margin-bottom: 1.5rem;
}

.discourse-post-avatar img {
  margin: 15px 15px 15px 0;
  border-radius: 4px;
}

.discourse-post-header {
  background: #e4e4e4;
  padding: 4px 10px 4px;
}

.discourse-post-header-username {
  font-weight: bold;
}

.discourse-content {
  margin-top: 0.5rem;

  .emoji {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  blockquote {
    background-color: #f0efeb;
    border-left: 5px solid #e9e9e9;
    border-left-color: #d0d0d0;
  }

  .quote {
    margin-top: 1em;
    margin-bottom: 1em;

    .title,
    blockquote {
      background-color: #f0efeb;
      border-left: 5px solid #e9e9e9;
      border-left-color: #d0d0d0;
    }

    .title {
      padding: 12px 12px 1px 12px;
      margin-bottom: 0;
    }

    blockquote {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 12px;
    }
  }

  h2 {
    margin: 30px 0 10px;
    font-size: 1.5em;
    font-weight: bold;
  }
}
