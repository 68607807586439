
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@import '~bulma/sass/utilities/initial-variables.sass';

.img-container {
  width: 100%;
  height: 200px;
  transition: 0.2s;
  background-color: #e2e2e2;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;

  &:hover {
    // background-color: black;
    transition: 0.2s;

    .exif {
      background-color: rgba(83, 83, 83, 0.58);
    }

    .remove-image-btn,
    .exif p {
      opacity: 1;
      transition: 0.2s;
    }
  }

  progress {
    position: absolute;
    bottom: 0;
  }
}

.card-content {
  height: 115px;
  overflow-y: hidden;
  padding: 0;

  .category-select {
    padding: 0.5rem;

    label {
      display: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
    }
  }

  .title-input {
    margin-bottom: 0;

    input {
      padding: 19px 0.5rem;
      border: 0;
      outline: 0;
      display: block;
      width: 100%;
      font-size: 1rem;
    }
  }

  .licence-select {
    border-top: 1px solid #ddd;
    max-width: 100%;

    .column:not(:last-child) {
      border-right: 1px solid #ddd;
    }

    label {
      padding: 19px 0.5rem;
      display: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
    }
  }
}

.action-buttons {
  position: absolute;
  top: -1rem;
  right: -1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 5px;
  }
}

.buttons-if-failed {
  margin-top: 3em;
}
