
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.toolbox-button {
  cursor: pointer;

  span {
    color: $link;
  }
}

.lock-button-green {
  color: $green !important;
}

.lock-button-red {
  color: $red !important;
}

.lang-switcher-box-list span:not(:last-child)::after {
  content: ' \2022 '; /* \2022 is bull */
}

.quality {
  span:nth-of-type(1) {
    @include colon;
  }
}
