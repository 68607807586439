
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.images-container {
  min-height: 200px;
}

h4 > a,
h6 > a {
  color: $color-text !important;
}

h4 > a:hover,
h6 > a:hover {
  color: $color-link !important;
}
