
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.association-image-link-child {
  display: table-cell;
  vertical-align: middle;
}

.association-image-link-child:last-child {
  padding-left: 10px;
}
