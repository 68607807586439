
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.title {
  margin-bottom: 0.2rem !important;
}

.associations-list:not(:last-child) {
  margin-bottom: 1rem;
}

.icon-link,
.icon-link:hover {
  color: $dark;
  margin-right: 3px;
}
