
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.toolbox-button {
  display: block;
  cursor: pointer;

  span {
    color: $link;
  }

  :hover {
    color: $text;
  }

  .toolbox-button-icon {
    width: 1rem;
    color: $text;
  }
}
