
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@media print {
  .is-size-3 {
    font-size: 1rem !important;
  }
}
