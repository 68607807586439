/* Full links in markdown content, like https://... http://... //... get an external link icon */

$escaped-color-link: '%23#{str-slice(inspect($color-link), 2)}';

.markdown-content {
  a[href*="//"]::after
  {
    content: ' ';
    // Icon from https://fontawesome.com/icons/external-link-alt?style=solid (CC BY 4.0)
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#{$escaped-color-link}' d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path></svg>")
      center no-repeat;

    padding-right: 1em;
    background-size: 0.6em;
  }

  /* except camptocamp full links */
  a[href^="https://www.camptocamp.org"]::after,
  a[href^="https://forum.camptocamp.org"]::after,
  a[href^="https://media.camptocamp.org"]::after
  {
    content: none;
  }

  /* don't show the icon when printed, but display the url */
  @media print {
    a[href*="//"]::after
    {
      content: ' [' attr(href) ']';
      font-size: 0.6em;
      background: none;
      padding-right: 0;
    }
  }
}
