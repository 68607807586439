
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.accordion-title {
  position: relative;
}

.accordion-icon {
  position: absolute;
  top: 0;
  right: 0;
}
