
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.modal-card-body,
.modal-card,
.modal {
  overflow: visible !important;
}

.column {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.has-padding-bottom {
  padding-bottom: 1rem;
}
