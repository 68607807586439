
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

swiper-slide {
  height: 200px;
  width: auto;
  cursor: pointer;

  picture::v-deep img {
    height: 200px;
  }
}
