
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.missing-history-banner {
  overflow: hidden;
}

.protection-area-info {
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.automatic-gears {
  margin-bottom: 1.5rem;
}
@media print {
  .protection-area-info {
    margin: 0rem !important;
    padding: 0rem !important;
  }
}
