
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.success-indicator {
  font-size: 2em;
  color: $green;
}

.success-indicator-enter-active,
.success-indicator-leave-active {
  transition: opacity 0.5s;
}
.success-indicator-enter-active {
  animation: bounce-in 0.5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.success-indicator-enter,
.success-indicator-leave-to {
  opacity: 0;
}
