
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@media print {
  /* write the activity's title instead of the icon */
  .replace-icon-by-names-on-print {
    color: $text !important;
  }

  .replace-icon-by-names-on-print:not(:last-child):after {
    content: attr(title) ', ';
  }

  .replace-icon-by-names-on-print:last-child:after {
    content: attr(title);
  }
}
