
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.topoguide {
  background-image: url('~@/assets/img/backgrounds/laurentf-sajama-800w.jpg');
  background-position-x: center;
  background-size: cover;
  padding-top: 90px; //keep the header on a deep color zone
  padding-bottom: 25px;
  justify-content: center;
  // 1224px because after 1024px breakpoint, we have an additional 200px wide menu on the left
  @media (min-width: 800px) and (max-width: 1224px) {
    background-image: url('~@/assets/img/backgrounds/laurentf-sajama-1024w.jpg');
  }
  @media (min-width: 1224px) {
    background-image: url('~@/assets/img/backgrounds/laurentf-sajama-1200w.jpg');
  }

  h1 {
    font-size: 3rem;
    color: $light;
    margin-bottom: 25px;
  }

  .links {
    justify-content: center;
    flex-flow: wrap row;
    max-width: 700px;
    margin: auto;
  }
}

.title {
  line-height: 1.5 !important;
}
