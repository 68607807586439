
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@import '@/assets/sass/variables';

.separator {
  background-color: $color-base-c2c-lighter;
}
.star-icon {
  color: $yellow;
}
.winner-image {
  height: 250px;
}

.cards-container {
  flex-flow: wrap row;
  justify-content: space-between;

  .card-image {
    transition: 0.2s;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 200px;
    text-align: center !important;

    img {
      height: 200px;
    }
  }
}
