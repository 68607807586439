
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.cards-container {
  flex-flow: wrap row;
  //margin:auto;

  .card-image {
    transition: 0.2s;
    margin-bottom: 5px;
    flex-grow: 1;

    img {
      height: 200px;
    }
  }
}
