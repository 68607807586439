
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.filter-section {
  padding-bottom: 0.5rem;
  clear: both;
}
.empty-screen-button {
  padding-top: 1rem;
}
