
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.date-container {
  padding-right: 0.5rem !important;
}

.hour-container {
  .select select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.minute-container {
  .select select {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
