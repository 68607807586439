
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.item-icons {
  display: inline-block;
  width: 2.2rem;

  svg {
    margin: 0 0.1rem;
  }
}

span:nth-child(2) {
  padding-left: 0.5rem;
}
