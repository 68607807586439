@mixin colon {
  :lang(fr) &::after {
    content: '\00a0:';
  }
  :lang(ca) &::after,
  :lang(de) &::after,
  :lang(en) &::after,
  :lang(es) &::after,
  :lang(eu) &::after,
  :lang(hu) &::after,
  :lang(it) &::after,
  :lang(sl) &::after,
  :lang(zh) &::after {
    content: ':';
  }
}

@mixin button-badge-primary {
  color: $primary;
  background-color: $primary-invert;
  border-radius: 1em;
  padding: 0 0.35em;
}
