
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.dashboard-link {
  align-items: center;

  .activity-icons {
    line-height: 1;
    display: flex;
    flex-wrap: nowrap;
  }

  .dashboard-link-main {
    flex-grow: 1;
    padding-left: 3px;
    padding-right: 3px;
  }

  .document-title:after {
    content: '\0000a0\002022\0000a0'; //&nbsp;&bull;&nbsp;
  }

  .area-title:not(:last-child):after {
    content: ', ';
  }
}

.dashboard-link:visited {
  color: $grey-light;
}

@media screen and (max-width: $tablet) {
  .is-2-columns {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
}
