
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.gdpr-content {
  z-index: 30;
  position: fixed;
  top: 0;
  padding: 10px;

  margin: 0 auto;
  flex-direction: column;
  display: flex;
  background-color: white;
  box-shadow: none;
  transform: translateY(-100%);
  transition: transform 0.25s ease-in-out;

  &.active {
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 6px 0;
    transform: translateY(0);
  }
}

@media screen and (min-width: $desktop) {
  .gdpr-content {
    width: 50%;
    left: calc(25% - 10px);

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
