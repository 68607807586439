
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

$C2C-orange: red;

:deep(.elevation-profile-chart) {
  height: 300px; // default height on page

  .axis path,
  .axis line {
    fill: none;
    stroke: black;
    shape-rendering: crispEdges;
  }

  .axis .legend {
    fill: black;
  }

  .line {
    fill: none;
    stroke: $C2C-orange;
    stroke-width: 3px;
  }

  .circle {
    fill: white;
    stroke: $C2C-orange;
    stroke-width: 2px;
  }

  line.target {
    stroke: lightgray;
    stroke-width: 1px;
  }

  .bubble {
    font-size: 12px;
  }

  .overlay {
    fill: none;
    pointer-events: all;
  }
}

:deep(.elevation-profile-fullscreen-toggle) {
  display: none;
}

:fullscreen .elevation-profile-container {
  $shadow: 1px -4px 5px rgb(0 0 0 / 20%);

  position: absolute;
  bottom: 0;
  height: 30%;
  max-height: 350px;
  min-height: 200px;
  width: 100%;
  background-color: white;
  box-shadow: $shadow;

  &.hidden {
    height: 0;
    min-height: 0;
  }

  > .elevation-profile-fullscreen-toggle {
    $height: 23px;
    display: inline-block;
    position: absolute;
    width: 48px;
    height: $height;
    top: -$height;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
    border: 0;
    padding: 0;
    box-shadow: $shadow;
  }

  > form {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  .elevation-profile-chart {
    height: 100%;

    :deep(svg) {
      position: absolute;
    }
  }
}
