
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

$button-width: 78px;
$button-height: $button-width;
$button-radius: 5px;
$button-inner-radius: 2px;
$button-selected-border: 3px;
$config-button-width: 17px;
$config-button-height: $config-button-width;

.layer-button {
  position: relative;
  width: $button-width;
  height: $button-height;
  margin: 10px;
  cursor: pointer;
  border-radius: $button-radius;
  padding: 5px;
  vertical-align: bottom;

  &:hover {
    filter: brightness(80%);
  }
}

.layer-button.selected > .layer-button-border {
  position: absolute;
  top: 0;
  left: 0;
  width: $button-width;
  height: $button-width;
  border-radius: $button-radius;
  border: $button-selected-border solid #fff;
  pointer-events: none;
}

.layer-button-title {
  position: absolute;
  padding-bottom: 2px;
  bottom: 0;
  left: 0;
  width: $button-width;
  border-bottom-left-radius: $button-radius;
  border-bottom-right-radius: $button-radius;
  font-size: 0.8em;
  text-align: center;
  background-color: rgba($color: #000, $alpha: 0.5);
}

.layer-button-country {
  width: 20px;
  height: 15px;
  background-size: contain;
  border-radius: $button-inner-radius;
}

.layer-button-config {
  position: absolute;
  top: 0;
  right: 0;
  width: $config-button-width;
  height: $config-button-height;
  border-radius: 0 $button-radius 0 $button-inner-radius;
  background-color: rgb(0, 59, 136, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.layer-button.show-slider .layer-button-config {
  border-radius: 0 $button-radius 0 0;
}

.layer-button.selected .layer-button-config {
  top: 2px;
  right: 2px;
}

.layer-button-slider {
  position: absolute;
  background-color: rgb(0, 59, 136, 0.7);
  box-sizing: content-box;
  border-radius: $button-radius 0 0 0;
  top: 0;
  left: 0;
  width: $button-width - $config-button-width;
  height: $config-button-height;
}

.layer-button.selected .layer-button-slider {
  top: $button-selected-border - 1;
  width: $button-width - $config-button-width - $button-selected-border - 1;
  padding-left: $button-selected-border - 1;
}
