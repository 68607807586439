
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.site-notice {
  a,
  a:hover {
    color: currentColor;
    text-decoration: underline;
  }
}
