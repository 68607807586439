
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.markdown-editor {
  border: 1px solid lightgrey;
  box-shadow: none;
  transition: 300ms;
  border-radius: 3px;
  background: white;

  .button-bar {
    padding: 0.5rem;
    background: #eee;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 1px dashed lightgrey;
  }

  .buttons {
    margin: 0;
  }

  .markdown-editor-content {
    position: relative;
  }

  textarea,
  textarea:focus {
    font-family: monospace;
    border: 0;
    box-shadow: none;
    min-height: 100px;
  }

  .preview,
  .preview-error {
    padding: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #ffffe0;
    overflow-y: auto;
  }
}

.is-active {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  transition: 300ms;
}

.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .markdown-editor-content {
    flex-grow: 1;

    textarea {
      height: 100%;
      resize: None;
      max-height: None;
    }
  }
}
