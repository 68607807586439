
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.serac-view {
  background-image: url('~@/assets/img/backgrounds/cermygian-crevasse-800w.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100%;
  // 1224px because after 1024px breakpoint, we have an additional 200px wide menu on the left
  @media (min-width: 800px) and (max-width: 1224px) {
    background-image: url('~@/assets/img/backgrounds/cermygian-crevasse-1024w.jpg');
  }
  @media (min-width: 1224px) {
    background-image: url('~@/assets/img/backgrounds/cermygian-crevasse-1200w.jpg');
  }

  .serac-view-content {
    padding: 10px;
    padding-top: 60px;
    margin: auto;
    max-width: 880px;
  }
}

.serac-block {
  background-color: rgba(21, 50, 69, 0.4);
  padding: 20px;
  height: 100%;
  transition: background-color 300ms;

  ul {
    margin-left: 1em !important;
  }
}

.serac-block:hover {
  background-color: rgba(21, 50, 69, 0.6);
}

.serac-activities {
  max-width: 699px;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: inline-block;
  width: 100%;
}

.topoguide-view-small-link {
  min-width: 10rem;
}
