
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@import '~bulma/sass/utilities/initial-variables.sass';

div {
  cursor: pointer;
  color: $primary;

  &.disabled {
    cursor: not-allowed;
    color: $primary-light;
  }
}
