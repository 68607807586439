
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

ul {
  list-style-type: disc !important;
  padding-left: $size-6;
}

h4 > a,
h6 > a,
li > a {
  color: $color-text !important;
}

h4 > a:hover,
h6 > a:hover {
  color: $color-link !important;
}

.dashboard-list > a:nth-child(2n + 1) {
  background-color: $body-background-color;
}
