
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.no-sort-filler {
  width: 20px;
}

.sort-item {
  display: flex !important;
}
.sort-label {
  flex-grow: 1;
  padding-right: 3em;
}
