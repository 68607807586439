
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

// TODO : modal background color with more alpha in Variables

.images-uploader {
  .images-uploader-message {
    position: relative;
    height: 315px;
    text-align: center;
    border: 5px dashed #ddd;
    background: #f8f8f8;
    transition: 0.2s;
    padding: 1rem;

    input {
      opacity: 0; /* invisible but it's there! */
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  .images-uploader-message:hover {
    background: #eee;
    border: 5px dashed #bbb;
  }

  .images-uploader-message-dragover {
    background: rgb(184, 238, 177);
    border: 5px dashed green;
  }
}
