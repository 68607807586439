//https://bulma.io/documentation/customize/variables/

/**
 * here is custom c2c variables
 */

// orange defined in V5
// please have a look on this discussion :
// https://forum.camptocamp.org/t/projet-ui-quel-est-le-orange-c2c/238878
$color-base-c2c: #f93;

$color-base-c2c-darker: #b26f1e;
$color-base-c2c-lighter: rgb(255, 206, 158);

$color-complementary-c2c: #337ab7; // must fit with c2c orange, and will be main color
$color-complementary-c2c-lighter: #7699b8;

$color-link: #337ab7; // We use the same blue as complementary-c2c

$color-text: #4a4a4a;

/**
 * Bulma variables
 */
$body-size: 14px !default;

// define size to get entire px size.
// TODO : is ot smarter to directlty set px values ?....

$size-1: 2.1429rem; // 30/14
$size-2: 1.8571rem; // 26/14
$size-3: 1.5714rem; // 22/14
$size-4: 1.2857rem; // 18/14
$size-5: 1.1429rem; // 16/14
// $size-6 : 1 rem,    untouched
$size-7: 0.7857rem; // 12/14

/**
 * customize bulma colors
 */
$green: #4baf50;
$primary: $color-complementary-c2c;
$secondary: $color-base-c2c;
$link: $color-link;
//$info: $cyan
//$success: $green
//$warning: $yellow
//$danger: $red
//$dark: $grey-darker
//$text: $grey-dark
$pagination-current-background-color: $primary;
$pagination-current-border-color: $primary;
$body-background-color: #fbfaf6; // if you modify this, please also modify it for spashscreen on public/index.html

$input-focus-border-color: $color-base-c2c;
$input-focus-box-shadow-size: 0 0 0 0; // with border orange, input focus shadow is terrible

// Paddings and margins
$section-padding: 1.5rem 1.5rem;
$section-padding-desktop: 1.5rem 1.5rem;
$hr-margin: 1rem 0;

/* box custom */
$box-radius: 0;
$box-shadow: rgba(10, 10, 10, 0.1) 0 2px 3px 0, rgba(10, 10, 10, 0.1) 0 0 0 1px;

/*
 * Here is bulma variables needed for components.
 * we only includes variables from utilities, and
 * locally other varaible if needded (like $navbar-height)
 */
$navbarad-height: 90px;
$navbar-height: 3.25rem;
$sidemenu-width: 200px;

$input-placeholder-color: rgb(160, 160, 160);

@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/functions.sass';
@import '~bulma/sass/utilities/derived-variables.sass';

// icons in input are to light
$input-icon-color: $grey-light;

/**
 * bulma card shadow is used to set a border. Remove the border part
 */
$card-shadow: 0 2px 3px rgba($black, 0.1);
$card-hover-shadow: 1px 3px 4px rgba($black, 0.1);

// and set a variable for card border :
$card-border: 1px solid rgba($black, 0.15);

/**
 * Another custom C2C var, but that are computed from bulma variables
 */

$hover-background: hsl(0, 0%, 95%);
