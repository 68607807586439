
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.input-orientation > g > path {
  fill: #ddd;
  transition: 0.1s;
}

.input-orientation:not(.is-read-only) > g {
  cursor: pointer;
}

.input-orientation > g.input-orientation-selected > path {
  fill: $primary;
  transition: 0.1s;
}

.input-orientation.is-black-white > g.input-orientation-selected > path {
  fill: $black;
  transition: 0.1s;
}

.input-orientation > g.input-orientation-selected > text {
  fill: $white;
  transition: 0.1s;
}

@media print {
  .input-orientation {
    display: none;
  }

  div:before {
    content: 'Orientations : ';
    font-weight: 700;
  }

  div:after {
    content: attr(title);
  }
}
