
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.tags-container {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.tag {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: $tablet) {
  .tag {
    max-width: 100%;
  }

  .document-title {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
