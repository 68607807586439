
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@media screen and (max-width: $tablet) {
  .feed-card {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .has-left-margin-mobile {
    margin-left: 5px;
  }
}

.has-left-padding {
  padding-left: 0.5rem;
}

.is-max-3-lines-height {
  // proprietary stuff, supported on limited browsers
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-image-content {
  display: flex;
  overflow: hidden;
}

.avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-right: 0.5rem;
}

.card-image-content > div > img {
  width: 100%;
  box-sizing: border-box;
}

.row {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  min-height: 29px;
  color: $text;
  align-items: center;

  .column {
    max-height: 58px;
  }
}

.is-not-ellipsed {
  white-space: normal !important;
  flex: 1;
}

.outing-icons {
  min-width: 5.4rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  span {
    padding: 0 0.1rem;
  }
}
