
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.card-header-title {
  padding: 0.4rem 0.8rem;
  min-width: 0;
  color: $text;

  align-items: center;
  justify-content: space-between;
  display: flex;
}
