
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.logo {
  height: 70px;
}

.qrcode {
  width: 70px;
}
