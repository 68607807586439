
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.icon-activities {
  line-height: 1;
}

@media print {
  .is-size-4 {
    font-size: 1rem !important;
  }
}
