
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

// Not scoped syle, because CSS selector are not explicitly present in template
.markdown-content {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem !important;
    margin-bottom: 0.5em !important;
    border-bottom: 1px solid #eee;
  }
  h4 {
    font-size: 1.1rem !important;
    margin-bottom: 0.25em !important;
  }
  h5 {
    font-size: 1rem !important;
    margin-bottom: 0.25em !important;
  }
  hr {
    margin: 1rem 0 !important;
  }
}

span[c2c\:role='header-emphasis'] {
  font-size: 80%;
  font-style: italic;
}

img[c2c\:role='emoji'] {
  width: 1.43em;
  height: 1.43em;
  vertical-align: bottom;
}

figure[c2c\:role='embedded-figure'] {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 0.5rem;
  background: #fafafa;
  max-width: 100%;
  display: table;

  img {
    cursor: pointer;
  }
}

// small image
// SI is a 200*200 image, no need to specify anything
// figure[c2c\:size=SI]{

// }

// medium image
figure[c2c\:size='MI'] {
  max-width: min(400px, 25vw);
  max-height: min(400px, 25vw);

  img {
    max-width: min(400px, 25vw);
    max-height: min(400px, 25vw);
  }
}

// big image
// figure[c2c\:size=BI]{

// }

// original size image
// TODO MARKDOWN PARSER HAS TO EXPOSE THIS EXPLICTLY
// figure[c2c\:size=OI]{

// }

figure[c2c\:position='right'] {
  float: right;
  clear: right;
  margin-right: 0 !important;
  z-index: 1;
  position: relative;
}

figure[c2c\:position='left'] {
  float: left;
  clear: left;
  margin-left: 0 !important;
  z-index: 1;
  position: relative;
}

figure[c2c\:position='center'] {
  margin-left: auto !important;
  margin-right: auto !important;
  clear: both;
}

figure[c2c\:position='inline'] {
  margin-right: 0 !important;
  margin-left: 0 !important;
  // well, no :(
  // display: inline-block;
}

table[c2c\:role='ltag'] {
  width: auto !important; // on prod, bulma CSS is after this CSS ??

  .pitch {
    font-weight: bold;
  }
}

div[c2c\:role='info'],
div[c2c\:role='warning'],
div[c2c\:role='danger'] {
  overflow: hidden;
}

div[c2c\:role='video'] {
  text-align: center;

  iframe {
    width: 400px;
    height: 250px;
    max-width: 100%;
    @media screen and (min-width: $tablet) {
      width: 420px;
      height: 315px;
    }
  }
}

@media screen and (max-width: $tablet) {
  table[c2c\:role='ltag'] {
    tr {
      display: flex;
      flex-flow: row wrap;
      padding: 0.4em 0 0.5em;

      &:not(:last-child) {
        border-bottom: 1px solid #d0d0d0;
      }
    }

    td,
    th {
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@media print {
  .markdown-content {
    &:not(:last-child) {
      margin-bottom: 0.5rem !important;
    }

    h3 {
      font-size: 1.2rem !important;
      margin-bottom: 0.25em !important;
    }
  }
}
