
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@media screen and (max-width: $tablet) {
  thead {
    display: none;
  }

  tr {
    border-top: 1px solid #ddd;

    &:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
  }

  td {
    display: block;
    padding: 0;
    padding-left: 10px;
    text-align: left;
    white-space: normal;
    border: 0 !important;
  }

  // hide cell with no data
  td:not([data-title]) {
    display: none;
  }

  td[data-title]::before {
    content: attr(data-title);
    display: block;
    color: #8d8d8d;
    padding: 0px 10px;
    margin-left: -10px;
    font-variant: small-caps;
    font-weight: 700;
    box-sizing: border-box;
  }
}
