@import './variables';

// modify layouts of checkradio

// radio and checkbox must have white background
.is-checkradio[type='checkbox'] + label::before,
.is-checkradio[type='radio'] + label::before {
  background: white;
}

// checkbox unchecked : 0-sized tick centered
.is-checkradio[type='checkbox'] + label::after {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  width: 0 !important;
  height: 0 !important;
  top: 0.8rem !important;
  left: 0.8rem !important;
  transition: 0.1s;
  display: inline-block;
}

// checkbox checked : visible tick, with good placement
.is-checkradio[type='checkbox']:checked + label::after {
  border-right-width: 3px;
  border-bottom-width: 3px;
  width: 0.4rem !important;
  height: 0.7rem !important;
  top: 0.35rem !important;
  left: 0.6rem !important;
}

// ra:dio unchecked : 0-sized tick centerd
.is-checkradio[type='radio'] + label::after {
  left: 0.75em !important;
  top: 0.75em !important;
  border-radius: 50%;
  transform: scale(0.5);
  display: inline-block;
  width: 0 !important;
  height: 0 !important;
  transition: 0.1s;
}

// radio checked : visible tick, with good placement
.is-checkradio[type='radio']:checked + label::after {
  left: 0 !important;
  top: 0 !important;
  width: 1.5em !important;
  height: 1.5em !important;
}

// use good primary color
.is-checkradio[type='radio'].is-primary:hover:not([disabled]) + label::before,
.is-checkradio[type='checkbox'].is-primary:hover:not([disabled]) + label::before {
  border-color: $primary !important;
}

.is-checkradio[type='checkbox'].is-primary:checked + label::after,
.is-checkradio[type='checkbox'].is-primary + label::after {
  border-color: $primary !important;
}

.is-checkradio[type='radio'].is-primary:checked + label::after,
.is-checkradio[type='radio'].is-primary + label::after {
  border-color: $primary !important;
  background-color: $primary !important;
}
