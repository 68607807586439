
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

@import '~bulma/sass/utilities/mixins.sass';

.map-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: beige;

  &.fill-parent {
    height: 275px;
  }
}

:not(:fullscreen) > .map-container {
  &.pinned {
    position: fixed;
    top: $navbar-height;
    margin-top: 0; /*avoid space between nav and map, where body text can be seen while scrolling*/
    z-index: 10; /* on top of body but below navbar (z=25) and side-menu (z=30) */
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4); // <o-x> <o-y> <radius>
  }
  &.pinned-to-top {
    right: 0px;
    height: 45vh;
    width: 100%;
    @include desktop {
      left: $sidemenu-width; /* when sidemenu is shown, as in App.vue */
    }
  }
  &.pinned-to-right {
    right: 0px;
    height: calc(100vh - #{$navbar-height});
    width: 30vw;
  }
}

/**
 * Fullscreen mode *with* elevation profile
 * Without the elevation profile it's the map-view
 * element that goes into fullscreen mode, so the
 * rule doesn't apply
 */
:fullscreen .map-container {
  height: 100%;
  max-height: 100%;
  margin: 0;

  &.with-elevation-profile {
    height: 70%;
    max-height: calc(100% - 200px);
    min-height: calc(100% - 350px);
  }
}
