
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

select,
input {
  min-width: 15rem;
}

.select,
select {
  width: 100%;
}

.has-prefix {
  display: flex;

  .prefix {
    cursor: default;
    background-color: whitesmoke;
    border-color: #dbdbdb;
    color: #7a7a7a;
    box-shadow: none;

    margin-right: -1px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  input,
  select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.prefix.is-danger {
  border-color: $danger;
}
