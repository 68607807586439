
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.pretty-route-link {
  display: block;
}

@media print {
  .is-size-4 {
    font-size: 1rem !important;
  }
}
