
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

nav {
  max-width: 100vw;
  height: $navbar-height;
  background-color: $white;
  box-shadow: 0 2px 0 $color-base-c2c;
  display: flex;
}

.navigation-brand {
  padding: 4px 5px !important;
  img {
    height: calc(#{$navbar-height} - 8px);
  }
}

.navigation-end {
  justify-content: flex-end;
  margin-left: auto;
  display: flex;
}

.navigation-item {
  display: flex;
  align-items: center;
  line-height: 1.5;
}

.c2c-color {
  background-color: $color-base-c2c;
}

.with-ad {
  height: $navbarad-height;
  margin-left: 200px;

  .navigation-end {
    flex-shrink: 1;
    margin-right: 1rem;
    flex-wrap: wrap-reverse;
    align-content: center;
  }

  .navigation-item {
    padding: 0.25rem 0.25rem;
  }
}

@media screen and (max-width: $tablet) {
  .navigation-brand {
    img {
      margin-left: 0px;
      // height:31px;
    }
  }

  .navigation-item {
    padding: 0.5rem 0.1rem;
  }

  .navigation-end {
    margin-right: 5px;
  }

  .search-input {
    width: 160px;
  }
}

@media screen and (min-width: $tablet) and (max-width: $desktop) {
  .navigation-brand {
    img {
      margin-left: 5px;
    }
  }

  .navigation-item {
    padding: 0.5rem 0.2rem;
  }

  .navigation-end {
    margin-right: 5px;
  }

  .search-input {
    width: 160px;
  }
}

@media screen and (min-width: $desktop) and (max-width: $widescreen) {
  .navigation-brand {
    img {
      margin-left: 20px;
    }
  }

  .navigation-item {
    padding: 0.5rem 0.75rem;
  }

  .navigation-end {
    margin-right: 1rem;
  }

  .search-input {
    width: 160px;
  }
}

@media screen and (min-width: $widescreen) and (max-width: $fullhd) {
  .navigation-brand {
    img {
      margin-left: 20px;
    }
  }

  .navigation-item {
    padding: 0.5rem 0.75rem;
  }

  .navigation-end {
    margin-right: 1rem;
  }
}

@media screen and (min-width: $fullhd) {
  .navigation-brand {
    img {
      margin-left: 20px;
    }
  }

  .navigation-item {
    padding: 0.5rem 0.75rem;
  }

  .navigation-end {
    margin-right: 1rem;
  }
}

// search input : increase size to right on hover
// only on screen wider than desktop
@media screen and (min-width: $fullhd) {
  .search-input {
    width: 250px;
    margin-right: 50px;
    transition: width 0.5s ease, margin-right 0.5s ease;
  }

  .search-input:hover {
    width: 300px;
    margin-right: 0;
  }
}
