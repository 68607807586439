
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.advertisement {
  width: 160px;
  height: 320px;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  iframe {
    height: 320px;
    width: 160px;
  }

  .advertisement-fallback {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 160px;
      height: 320px;
    }
  }
}
