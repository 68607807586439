::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: slategray;
  border: 48px none #fff;
  border-radius: 67px;

  &:hover {
    background: gray;
  }
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
  border: 52px none #fff;
  border-radius: 0;

  &:hover {
    background: #e3e3e3;
  }

  &:active {
    background: #dfdfdf;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}
