
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.account-view {
  form {
    width: 30rem;
    max-width: 100%;
  }
}
