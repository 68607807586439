@import './variables';

.has-hover-background:hover {
  background: $hover-background;
}

// force nowrap
.is-nowrap {
  white-space: nowrap;
}

// Force a inline element to nowrap, and ellipse overflow
.is-ellipsed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: $tablet) {
  .is-ellipsed-tablet {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.has-cursor-pointer {
  cursor: pointer;
}

.card-icon {
  color: $text;
}

.has-text-secondary {
  color: $secondary;
}

.has-background-secondary {
  background-color: $secondary;
}

.has-text-normal {
  color: $text;
}

.has-rounded-corner {
  border-radius: $radius;
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media not print {
  .print-only {
    display: none !important;
  }
}

@media print {
  /* print styles go here */

  /* Printing from Firefox with content display:flex will print only the first page.
    https://bugzilla.mozilla.org/show_bug.cgi?id=1241323
    */
  .is-block-print {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  .print-image .no-print.image-box {
    display: block !important;
  }

  .print-image .no-print.image-box .swiper-wrapper {
    flex-wrap: wrap !important;
    justify-content: space-evenly !important;
  }

  .has-background-white-print {
    background-color: white !important;
  }

  .box {
    padding: 0;
    box-shadow: none;
    border-bottom: 1px solid lightGrey;
  }

  .is-12-print {
    width: 100% !important;
  }

  @media print {
    .print-no-columns {
      display: block !important;

      @each $size in 1, 2, 3, 4, 5, 6, 7, 9, 10, 11 {
        .is-#{$size} {
          width: 100%;
        }
      }
    }
  }
}
