
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.icon-condition-excellent {
  color: green;
}

.icon-condition-good {
  color: #9acd32;
}

.icon-condition-average {
  color: yellow;
}

.icon-condition-poor {
  color: red;
}

.icon-condition-awful {
  color: #8b0000;
}

.icon-condition-null {
  color: lightgrey;
}
