
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.input-item {
  font-size: 40px;
  margin: 4px;
  color: #888;
  transition: color 300ms;
  user-select: none;

  svg {
    background: white;
    border-radius: 100%;
    box-shadow: 2px 2px 4px grey;
    transition: box-shadow 100ms;
  }
}

.input-item:hover {
  svg {
    box-shadow: 3px 3px 5px grey;
  }
}

.input-item[checked] {
  color: $primary;
}

.with-labels {
  width: 100px;
  display: inline-flex;
  flex-direction: column;

  svg {
    margin: auto;
  }

  .input-label {
    text-align: center;
  }
}

.has-error {
  box-shadow: 0px 0px 8px 0px $danger;
  // border: 1px solid $danger;
}
