
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.login-view {
  height: 100%;
  align-items: center;
  width: 30rem;
  max-width: 100vw;
  margin: auto;

  form {
    width: 100%;
    padding: 1.5rem;
  }
}
