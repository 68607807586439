
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.markdown-section {
  .title {
    font-size: 1.8rem !important;
    margin-bottom: 0.5em !important;
    border-bottom: 1px solid #ddd;
  }

  &:nth-last-child(n + 3) {
    margin-bottom: 1.5rem;
  }
}

@media print {
  .markdown-section {
    .title {
      font-size: 1.2rem !important;
      margin-bottom: 0.25em !important;
    }
  }
}
