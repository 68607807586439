
          @import '@/assets/sass/variables.scss';
          @import '@/assets/sass/mixins.scss';
        

.rounded {
  border-radius: 6px;
}

.grayed-out {
  filter: grayscale(0.8);
}
.status {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: help;
}
